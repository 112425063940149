import React from 'react'
import { Helmet } from 'react-helmet'

export default () => (
  <div className="document document_no_spaces">
    <Helmet>
      <title>Политика конфиденциальности ТОО «ТОМЕ» :. tome</title>
      <meta property="og:title" content="Политика конфиденциальности ТОО «ТОМЕ»" />
      <meta property="og:url" content="https://tome.ge/#document-privacy-policy" />
      <meta property="og:type" content="article" />
    </Helmet>
    <aside>
      <span>01 июня 2021 г.</span>
    </aside>
    <h2>Политика конфиденциальности ТОО «ТОМЕ»</h2>
    <p>
      <strong>Компания</strong>
      {' – ТОО «ТОМЕ» (БИН 220340032539), адрес местонахождения: A05K3F2, г. Алматы, ул. Шевченко д.90'}
    </p>
    <p>
      <strong>Клиент</strong>
      {' – физическое лицо, пользователь сети Интернет, использующий любые сервисы Компании в собственных целях, а также иное физическое лицо, в интересах которого действует этот пользователь.'}
    </p>
    <p>
      <strong>Контрагент</strong>
      {' – юридическое лицо, индивидуальный предприниматель, физическое лицо, вступившее или намеревающееся вступить в договорные отношения с Компанией.'}
    </p>
    <p>
      <strong>Сервис(ы) Компании</strong>
      {' – Компания предлагает Клиентам доступ к широкому спектру сервисов, в том числе с использованием банковских карт, размещения и хранения разного рода информации и материалов (контента), персонализации контента и т. д., а также сервисов, специально предназначенных для использования Контрагентами.'}
    </p>
    <p>
      <strong>Коммуникация с Компанией</strong>
      {' – любое взаимодействие Клиента с Компанией в целях, не связанных с использованием Сервисов Компании, включая участие Клиента в проводимых Компанией в сети Интернет-опросах, играх, конкурсах, акциях, отклик Клиента на размещаемые Компанией вакансии, но не ограничиваясь перечисленным.\n'}
    </p>
    <h3>Преамбула</h3>
    <p>Настоящая Политика конфиденциальности персональной информации (далее – Политика) действует в отношении информации, которую Компания может получить о Клиенте. Политика применима только к Сервисам Компании и Коммуникациям с Компанией. Компания не контролирует и не несет ответственность за сайты / сервисы третьих лиц, на которые Клиент может перейти по ссылкам, доступным из Сервисов Компании, в том числе за обрабатываемую третьими лицами информацию о Клиенте.</p>
    <p>Использование Сервисов Компании означает безоговорочное согласие Клиента с настоящей Политикой и указанными в ней условиями обработки его персональной информации; в случае несогласия с этими условиями Клиент должен воздержаться от использования Сервисов Компании.</p>

    <h3>1. Информация Клиентов, которую получает и обрабатывает Компания</h3>
    <p>1.1. В рамках настоящей Политики под персональной информацией Клиента понимаются:</p>
    <p>1.1.1. Персональная информация, которую Клиент предоставляет о себе самостоятельно или через третье лицо:</p>
    <div className="document_list">
      <p className="document_listItem">при регистрации в любом из Сервисов Компании;</p>
      <p className="document_listItem">в процессе использования Сервисов Компании;</p>
      <p className="document_listItem">при Коммуникации с Компанией;</p>
      <p className="document_listItem">при переговорах, заключении и исполнении договоров с Контрагентами;</p>
    </div>
    <p>Использование Клиентом определенных Сервисов Компании/Коммуникаций с Компанией возможно только при условии предоставления необходимых данных.</p>
    <p>1.1.2. Данные, которые автоматически передаются Компании в процессе использования Сервисов Компании с помощью установленного на устройстве Клиента программного обеспечения или в процессе обмена с Клиентом электронными сообщениями, в том числе IP-адрес, информация из cookie (текстовые файлы, хранящиеся в браузере Клиента), информация о браузере Клиента (или иной программе, с помощью которой осуществляется доступ к Сервисам Компании), время доступа, адрес запрашиваемой страницы. Сбор такой информации позволяет Компании сохранять индивидуальные настройки и параметры Клиента (в том числе посетителя веб-сайта), улучшать функциональные характеристики Сервисов Компании, делать их более удобными для Клиентов и в целом повышать их эффективность, а также оценивать продуктивность маркетинговой деятельности Компании.</p>
    <p>1.1.2.1. При просмотре веб-сайтов Компании может происходить автоматическое размещение файлов cookie и (или) сбор следующих обезличенных статистических (аналитических), технических и функциональных типов данных, в том числе:</p>
    <div className="document_list">
      <p className="document_listItem">тип выполненного на сайте действия (клик, наведение курсора и т.п.);</p>
      <p className="document_listItem">дата и время выполнения действия;</p>
      <p className="document_listItem">URL страницы;</p>
      <p className="document_listItem">Referer;</p>
      <p className="document_listItem">IP (без возможности работы с IP-адресами в статистике);</p>
      <p className="document_listItem">User-Agent;</p>
      <p className="document_listItem">ClientID (идентификатор браузера по файлу cookie);</p>
      <p className="document_listItem">экранное разрешение;</p>
      <p className="document_listItem">класс HTML-элемента, на который происходит клик;</p>
      <p className="document_listItem">данные о фактах заполнения форм/обращений на сайте Компании, включая ошибки при их заполнении;</p>
      <p className="document_listItem">(сторонние) целевые и (или) рекламные файлы cookie;</p>
    </div>
    <p>Эти файлы cookie могут собирать обезличенную информацию о Клиентах, посещенных страницах и рекламе, отображенной для Клиента, а также той ссылке, по которой Клиент перешел на сервис Компании. Они также могут использоваться в статистических и исследовательских целях, а также в целях: показа соответствующей и персонализированной рекламы; ограничения количества показов каждого конкретного типа рекламы; определения эффективности рекламной кампании;</p>
    <p>Эти файлы cookie могут быть связаны с функциями веб-сайтов Компании, предоставляемыми соответствующей третьей стороной, которая также может получать и обрабатывать обезличенную информацию, полученную с использованием таких файлов.</p>
    <p>1.1.2.2. Пользуясь веб-сайтом Компании, Клиент соглашается, что Компания может использовать данные и файлы сookies, отнесенной действующим законодательством РФ к информации ограниченного доступа, для их последующей обработки системами, например, Google Analytics, Яндекс.Метрика, Google Firebase, Appmetrica и может передавать третьему лицу для проведения исследований, выполнения работ или оказания услуг Компанией. Клиент может самостоятельно управлять файлами cookie путем изменения настроек браузера, функционирующего на его оборудовании. Изменения пользовательских настроек, в результате которых файлы cookie будут заблокированы, могут привести к недоступности отдельных компонентов веб-сайта Компании.</p>
    <p>1.1.3. Компания может получить общедоступную информацию, когда Клиент использует сторонние ресурсы (например, чаты/форумы/социальные сети). Эти данные могут содержать информацию, которую Клиент публикует, в том числе в виде комментариев или отзывов о Сервисах Компании. Компания использует такие сведения в целях повышения качества обслуживания Клиентов.</p>
    <p>1.1.4. Компания может обрабатывать определенные данные (например, IP-адрес, идентификатор пользовательского устройства) для выявления и (или) предотвращения условий, способствующих совершению с использованием банковской карты действий, противоречащих требованиям законодательства.</p>
    <p>1.2. Компания не проверяет достоверность персональной информации, предоставляемой Клиентами, и не осуществляет контроль за их дееспособностью. Однако Компания исходит из того, что Клиент предоставляет достоверную и достаточную персональную информацию и поддерживает эту информацию в актуальном состоянии.</p>

    <h3>2. Цели сбора и обработки персональной информации Клиентов</h3>
    <p>2.1. Компания собирает и хранит только ту персональную информацию, которая необходима для предоставления Сервисов Компании, исполнения соглашений с Клиентом или Коммуникации с Компанией, за исключением случаев, когда законодательством предусмотрено обязательное получение и хранение определенной персональной информации. Хранение персональной информации осуществляется не дольше, чем этого требуют цели обработки, если только срок хранения такой персональной информации не установлен законодательством РФ, соглашением об использовании Клиентом сервисов Компании.</p>
    <p>2.2. Персональную информацию Клиента Компания может использовать в следующих целях:</p>
    <p>2.2.1. Заключения и исполнения соглашений Клиента /Контрагента с Компанией, в том числе в целях проведения идентификации/упрощенной идентификации Клиента/Контрагента, создания учетных записей Клиента/Контрагента;</p>
    <p>2.2.2. Осуществления связи с Клиентом, в том числе направления уведомлений, запросов и информации, касающихся использования Сервисов Компании, а также обработки запросов и заявок от Клиента;</p>
    <p>2.2.3. Улучшения качества Сервисов Компании, удобства их использования, разработки новых Сервисов Компании, предложения Клиенту персонализированных Сервисов Компании;</p>
    <p>2.2.4. Проведения статистических и иных исследований на основе обезличенных данных;</p>
    <p>2.2.5. Проведения маркетинговых акций для Клиентов, в том числе в целях распространения предложений об участии в акции и получения предусмотренных акцией призов/вознаграждений; распространения рекламно-информационных материалов по сетям электросвязи, в том числе посредством использования телефонной, факсимильной, подвижной радиотелефонной связи, или путем прямых контактов; таргетирования рекламных материалов и иной информации, доводимой до сведения Клиентов.</p>
    <p>2.2.6. Выявления и (или) предотвращения условий, способствующих использованию Сервисов Компании в противоправных или запрещенных правилами соответствующих Сервисов Компании целях.</p>
    <p>2.2.7. Повышения лояльности Клиентов к Сервисам Компании.</p>
    <p>2.2.8. Выявления и устранения ошибок в программном обеспечении Компании.</p>
    <p>2.2.9. Ознакомления потенциальных Клиентов и работников с Сервисами Компании, вакансиями, условиями работы в Компании.</p>
    <p>2.2.10. Обмена знаниями с Клиентами и Контрагентами, кандидатами на замещение вакансий в целях развития профессиональных навыков работников Компании и формирования профессиональных сообществ в сфере интересов Компании.</p>
    <p>2.2.11. Оценки кандидатов на замещение вакансий в Компании.</p>
    <p>2.2.12. Отбора кандидатов для бета-тестирования разрабатываемого Компанией программного обеспечения, интерфейсов и т.д.</p>
    <p>2.2.13. Отбора кандидатов в целях формирования мнения о тенденциях спроса на услуги, интересов разных групп населения, проведения иных исследований в целях разработки новых сервисов и улучшения текущих.</p>

    <h3>3. Условия обработки персональной информации Клиента и её передачи третьим лицам</h3>
    <p>3.1. Компания обрабатывает персональную информацию Клиентов в соответствии с настоящей Политикой, условиями конкретных сервисов и внутренними регламентами Компании.</p>
    <p>3.2. В отношении персональной информации Клиента обеспечивается ее конфиденциальность, за исключением случаев, когда соответствующая информация сделана Клиентом общедоступной. Клиент осведомлен и согласен, что его действия по размещению персональной информации при создании или редактировании Клиентом юзерпика (аватара) в любом из Сервисов Компании, имеющем соответствующую функциональность, влекут общедоступность размещенной информации.</p>
    <p>3.3. Компания вправе передать персональную информацию Клиента третьим лицам в следующих случаях:</p>
    <p>3.3.1. Клиент выразил свое согласие на такие действия;</p>
    <p>3.3.2. Передача необходима для использования определенного Сервиса Компании или сервиса партнера Компании.</p>
    <p>В частности, Персональная информация может быть передана третьим лицам следующих категорий:</p>
    <p>3.3.2.1. Кредитные организации и иные организации, осуществляющие переводы. Например, в целях соблюдения должного уровня безопасности онлайн платежей, совершаемых с использованием банковских карт, Компания может передавать сведения, перечень которых устанавливается протоколами безопасности платежных систем, банкам-эквайерам/-эмитентам, платежным системам.</p>
    <p>Передача сведений может носить обязательный характер, например, в части сведений о пользовательском оборудовании: IP-адрес, ОС, географические данные, ID/тип оборудования, используемый канал: браузер/ приложение, платежная авторизация, идентификация/ верификация или факультативный характер, например, в части сведений об индикаторах совпадения адресов, адресе электронной почты, номере мобильного телефона, о размере платежа, MCC.</p>
    <p>3.3.2.2. Партнеры по маркетингу и иные контрагенты Компании.</p>
    <p>(а) Компания может предоставить доступ к некоторым данным (например, статистике) для проведения маркетинговых и других исследований, а также к иным данным, позволяющим обеспечить передачу Клиенту рекламы, в том числе сторонних организаций, которая актуальна и может быть интересна Клиенту;</p>
    <p>(б) Компания может предоставить доступ к адресу электронной почты или к номеру телефона Клиента и/или Контрагента, позволяющему партнеру Компании обеспечить передачу фискального или иного документа, предусмотренного законодательством Республики Казахстан;</p>
    <p>(в) При использовании Клиентом сервисов и услуг, предоставляемых партнерами Компании, информация о Клиенте может предоставляться таким лицам в объеме и в целях, необходимых для надлежащего предоставления сервисов Клиенту или повышающих уровень удобства их использования, например, для предзаполнения регистрационных форм, обеспечивающего ускорение процесса прохождения процедуры регистрации в сервисах, предоставляемых партнерами Компании.</p>
    <p>3.3.2.3. Партнеры, обеспечивающие хранение данных по поручению Компании.</p>
    <p>3.3.2.4. Партнеры, привлекаемые Компанией в целях, указанных в п. 2.2.6. настоящей Политики.</p>
    <p>3.3.2.5. Общедоступная информация. Сервисы Компании могут иметь форумы и/или чаты, где Клиенты могут обмениваться идеями и общаться друг с другом. Публикуя сообщение на форуме или в чат-зоне, Клиенту следует иметь в виду, что такая информация будет общедоступна в режиме онлайн и такое размещение осуществляется Клиентом на его страх и риск.</p>
    <p>Клиент признает и соглашается с тем, что Компания не обязана просматривать контент любого вида, размещаемый и/или распространяемый Клиентом посредством своих сервисов, а также то, что Компания имеет право (но не обязанность) по своему усмотрению отказать Клиенту в размещении и/или распространении им контента или удалить любой контент, который доступен посредством Сервисов Компании. Клиент осознает и согласен с тем, что он должен самостоятельно оценивать все риски, связанные с использованием контента, включая оценку надежности, полноты или полезности этого контента, а также с тем, что технология работы сервисов может потребовать копирование (воспроизведение) Компанией контента Клиента, а равно его переработки для соответствия техническим требованиям того или иного сервиса.</p>
    <p>3.3.2.6. Переход контроля. Передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации.</p>
    <p>3.3.2.7. Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры.</p>
    <p>3.3.2.8. В целях обеспечения возможности защиты прав и законных интересов Компании или третьих лиц в случаях, когда у Компании имеются достаточные основания полагать, что Клиент нарушает условия применимого соглашения с Компанией и/или требования применимого законодательства.</p>
    <p>3.4. Вводя любые данные в одном из Сервисов Компании, Клиент соглашается на сохранение этих данных для возможности использования их повторно или в иных сервисах Компании без дополнительного повторного ввода.</p>
    <p>При обработке персональных данных Клиентов Компания руководствуется Федеральным законом «О персональных данных» и другими нормативными актами, регулирующими отношения в сфере обеспечения безопасности персональных данных.</p>
    <p>3.5. Раскрытие анонимных данных. Компания может раскрывать партнерам анонимные данные (то есть данные, которые не раскрывают личность Клиента ни прямо, ни косвенно), а также агрегированные данные (данные о группах и категориях Клиентов). Компания также может разрешить собирать анонимные и агрегированные данные в целях предоставления Клиентам возможности пользоваться отдельными функциями Сервисов своим партнерам, которые впоследствии могут передавать эти данные Компании.</p>

    <h3>4. Изменение и удаление Клиентом персональной информации, а также получение доступа к ней</h3>
    <p>4.1. Изменение и удаление персональной информации Клиента осуществляется в порядке, установленном соглашениями об использовании соответствующих Сервисов Компании. В рамках Сервисов Компании Клиенту может предоставляться функциональная возможность изменить (обновить, дополнить) или удалить предоставленную Клиентом информацию или её часть. Использование соответствующего функционала регулируется применимым к данному Сервису Компанией соглашениями.</p>
    <p>4.1.1. В пределах, установленных применимым законодательством, Компания сообщает об изменении или уничтожении персональной информации каждому получателю, которому была раскрыта персональная информация, кроме случаев, когда это оказывается невозможным или требует несоразмерного усилия.</p>
    <p>4.2. В соответствии с требованиями законодательства на Компанию может быть возложена обязанность осуществлять обработку / хранение полученной при использовании Сервисов Компании персональной информации Клиента. Такая обработка / хранение осуществляется Компанией в случаях, по основаниям и в течение сроков, установленных законодательством.</p>
    <p>4.3. Право на доступ</p>
    <p>4.3.1. В соответствии с применимым законодательством Клиент имеет право на доступ к информации, полученной Компанией, то есть имеете право запросить информацию относительно: (а) цели обработки; (б) категории обрабатываемых данных; (в) категорий получателей, которым были или будут переданы персональные данные Клиента; (г) срока хранения или критериев его определения, а также иных сведений.</p>
    <p>4.3.1.1. Если иное не реализовано в интерфейсе сервисов Компании, информация (копия) может предоставляться Компанией в письменной форме или при помощи иных средств связи.</p>
    <p>4.3.1.2. Если Компания имеет основания для сомнения относительно идентификации личности Клиента, подающего запрос согласно п. 4.3.1 Политики, Компания вправе затребовать предоставления дополнительной информации, необходимой для подтверждения личности такого Клиента.</p>
    <p>4.3.1.3. Рассмотрение запроса о предоставлении сведений (копии) осуществляется в течение одного месяца (30 дней) с момента его получения Компанией. Компания вправе продлить указанный срок на два месяца (60 дней) с учетом сложности и количества запросов. Компания информирует Клиента о таком продлении с указанием причин, послуживших основанием для такого продления.</p>
    <p>4.3.1.4. В случае если запрос не имеет явного обоснования, а также в случае чрезмерного количества запросов, Компания вправе взимать разумную плату за выполнение запроса (с учетом административных расходов) или отказаться от его выполнения. Компания также не сможет предоставить Клиенту информацию о нем, опубликованную в порядке, предусмотренном в п. 3.3.2.5. Политики.</p>
    <p>4.3.1.5. Клиент вправе самостоятельно передать информацию, полученную от Компании в порядке, предусмотренном в настоящем разделе, другому контролеру, в том числе через Компанию при наличии у Компании технической возможности.</p>
    <p>4.4. Право на возражение</p>
    <p>4.4.1. В пределах, установленных применимым законодательством, Клиент может отозвать любое свое согласие, которое им было ранее предоставлено, или представить свои возражения на законных основаниях в отношении обработки его персональной информации. В некоторых ситуациях отзыв согласия будет означать, что Клиент не сможет воспользоваться Сервисами Компании.</p>
    <p>4.4.2. В соответствии с применимым законодательством Клиент имеет право на подачу жалобы в надзорный орган.</p>
    <p>4.5. Использование Сервисов Компании детьми</p>
    <p>4.5.1. Без согласия законного представителя Сервисы Компании не предназначены для малолетних. Компания может использовать данные законных представителей для целей проверки возраста и применения тех или иных возрастных ограничений.</p>

    <h3>5. Меры, применяемые для защиты персональной информации Клиентов</h3>
    <p>5.1. Компания принимает необходимые и достаточные организационные и технические меры для защиты персональной информации Клиента от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.</p>
    <p>5.2. При обработке персональных данных Компания обеспечивает их безопасность и принимает необходимые организационные и технические меры для защиты персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения персональных данных, а также от иных неправомерных действий, путем установления в отношении таких данных режима конфиденциальности и контроля за его соблюдением, а также путем внедрения дополнительных мер защиты, реализующих требования законодательства Республики Казахстан, стандартов и внутренних организационно-распорядительных документов Компании.</p>

    <h3>6. Взаимодействие с Клиентами по вопросам обработки персональной Информации</h3>
    <p>6.1. Вопросы или замечания в отношении Политики могут быть направлены на электронный адрес: office@tome.ge</p>
    <p>6.2. Компания вправе не отвечать на вопросы, не имеющие отношения к положениям Политики, что не лишает Клиента возможности направить такие вопросы Компании по адресам, указанным на сайте Компании в сети «Интернет», раздел «Контакты».</p>
  </div>
)
