import React from 'react'
import classNames from 'classnames'
import GoogleMapReact from 'google-map-react'
import MediaQuery from 'react-responsive'
import mediaSize from '../../../constants/mediaSize'

import styles from './styles.module.scss'

import Parallax from '../../../constants/parallax'

export default class Information extends React.PureComponent {
  mapOptions = {
    mapTypeControl: false,
    zoomControl: false,
    fullscreenControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
  }

  coordinates = {
    kazahstan: {
      lat: 43.2452262,
      lng: 76.936662,
    },
    canada: {
      lat: 49.2831963,
      lng: -123.110158,
    },
  }

  constructor(props) {
    super(props)
    this.parallaxComponents = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ]
  }

  componentDidMount() {
    const { init, cleanup } = new Parallax(this.parallaxComponents)
    init()
    this.cleanupParallax = cleanup
  }

  componentWillUnmount() {
    this.cleanupParallax()
  }

  render() {
    const {
      mapOptions,
      coordinates,
    } = this
    return (
      <div className={styles.wrap}>
        <MediaQuery minWidth={mediaSize.maxPhoneSize}>
          <div className={styles.logoBobbleWrap}>
            <div className="base-layout">
              <img
                alt="blobTop"
                src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/innerPages/blob_top.png`}
                className={styles.blobTop}
                width={190}
              />
              <img
                alt="pinBig"
                src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/innerPages/pin_big.webp`}
                className={styles.illustrationPin}
              />
            </div>
          </div>
          <img
            alt="waveTop"
            src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/innerPages/wave_top.webp`}
            className={styles.waveTop}
          />
          <div className={styles.bobblesWrap}>
            <div className="base-layout">
              <div className={styles.bobbles}>
                <div
                  ref={this.parallaxComponents[0]}
                  data-parallax="8"
                  className={classNames(styles.bobble, styles.bobbleFirst)}
                >
                  <img
                    alt="firstIcon"
                    width={35}
                    /* eslint-disable-next-line max-len */
                    src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/information/header/first.png`}
                  />
                </div>
                <div
                  ref={this.parallaxComponents[1]}
                  data-parallax="4"
                  className={classNames(styles.bobble, styles.bobbleSecond)}
                >
                  <img
                    alt="secondIcon"
                    width={25}
                    /* eslint-disable-next-line max-len */
                    src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/information/header/second.png`}
                  />
                </div>
                <div
                  ref={this.parallaxComponents[2]}
                  data-parallax="4"
                  className={classNames(styles.bobble, styles.bobbleThird)}
                >
                  <img
                    alt="thirdIcon"
                    width={31}
                    /* eslint-disable-next-line max-len */
                    src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/information/header/third.png`}
                  />
                </div>
                <div
                  ref={this.parallaxComponents[3]}
                  data-parallax="8"
                  className={classNames(styles.bobble, styles.bobbleFourth)}
                >
                  <img
                    alt="fourthIcon"
                    width={31}
                    /* eslint-disable-next-line max-len */
                    src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/information/header/fourth.png`}
                  />
                </div>
                <div
                  ref={this.parallaxComponents[4]}
                  data-parallax="8"
                  className={classNames(styles.bobble, styles.bobbleFifth)}
                >
                  <img
                    alt="fifthIcon"
                    width={30}
                    /* eslint-disable-next-line max-len */
                    src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/information/header/fifth.png`}
                  />
                </div>
                <div
                  ref={this.parallaxComponents[5]}
                  data-parallax="4"
                  className={classNames(styles.bobble, styles.bobbleSixth)}
                >
                  <img
                    alt="sixthIcon"
                    width={45}
                    /* eslint-disable-next-line max-len */
                    src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/information/header/sixth.png`}
                  />
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
        <div className={styles.content}>
          <div className="base-layout">
            <div>
              <div className={styles.row}>
                <div className={classNames(styles.card, styles.cardInfo, styles.cardInfoTop)}>
                  <MediaQuery minWidth={mediaSize.maxPhoneSize}>
                    <img
                      alt="card"
                      /* eslint-disable-next-line max-len */
                      src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/information/cardBg.svg`}
                      className={styles.cardInfoBg}
                    />
                  </MediaQuery>
                  <img
                    alt="kazahstan"
                    /* eslint-disable-next-line max-len */
                    src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/information/flags/kazahstan.svg`}
                    className={styles.cardTextFlag}
                  />
                  <div className={styles.cardContent}>
                    <h2 className={styles.cardTitle}>
                      { 'ТOO «TOME»' }
                    </h2>
                    <p className={styles.cardText}>
                      { 'Живем и\u00A0работаем в Казахстане, в\u00A0красивом '
                      + 'городе\u00A0Алматы. Обязательно приходите в\u00A0гости, '
                      + 'будем очень рады.' }
                    </p>
                    <hr className={styles.cardHr} />
                    <pre className={styles.cardText}>
                      <MediaQuery minWidth={mediaSize.maxPhoneSize}>
                        { 'БИН 220340032539\n'
                        + '\n' }
                        <strong className={styles.cardTextStrong}>{'@tometeam'}</strong>
                        {'\n'}
                        {'E-mail: office@tome.kz'}
                        { '\nA05K3F2, г. Алматы, ул. Шевченко д.90' }
                      </MediaQuery>
                      <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
                        { 'ИНН 3666233589\n'
                        + 'ОГРН 1193668003583\n'
                        + '\n' }
                        <strong className={styles.cardTextStrong}>{'@tometeam'}</strong>
                        {'\n'}
                        {'E-mail: office@tome.kz'}
                        { '\nA05K3F2, г. Алматы,'
                        + '\nул. Шевченко д.90'}
                      </MediaQuery>
                    </pre>
                  </div>
                </div>
                <div className={classNames(styles.card, styles.cardMap, styles.cardMapTop)}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDO8F6GdMJ-ywMfoTRfrf-O9dmf1uMXj1o' }}
                    defaultCenter={coordinates.kazahstan}
                    defaultZoom={16}
                    options={mapOptions}
                  >
                    <img
                      lat={coordinates.kazahstan.lat}
                      lng={coordinates.kazahstan.lng}
                      alt="pinMap"
                      /* eslint-disable-next-line max-len */
                      src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/information/pinMap.svg`}
                      className={styles.cardMapPin}
                    />
                  </GoogleMapReact>
                </div>
              </div>

              <div className={styles.row}>
                <div className={classNames(styles.card, styles.cardInfo, styles.cardInfoBottom)}>
                  <MediaQuery minWidth={mediaSize.maxPhoneSize}>
                    <img
                      alt="card"
                      /* eslint-disable-next-line max-len */
                      src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/information/cardBg.svg`}
                      className={styles.cardInfoBg}
                    />
                  </MediaQuery>
                  <img
                    alt="canada"
                    /* eslint-disable-next-line max-len */
                    src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/information/flags/canda.svg`}
                    className={styles.cardTextFlag}
                  />
                  <div className={styles.cardContent}>
                    <h2 className={styles.cardTitle}>
                      { 'TOME TRANSFER LTD' }
                    </h2>
                    <p className={styles.cardText}>
                      { 'Для работы с\u00A0нерезидентами вам поможет наша международная компания, '
                        + 'зарегистрированная в\u00A0красивом городе Канады, Ванкувер.' }
                    </p>
                    <hr className={styles.cardHr} />
                    <pre className={styles.cardText}>
                      <MediaQuery minWidth={mediaSize.maxPhoneSize}>
                        { 'IN BC1267245  /  MSB M20081256\n'
                          + '\n'}
                        <strong className={styles.cardTextStrong}>{'@tometeam'}</strong>
                        {'\n'}
                        {'E-mail: office@tome.kz'}
                        {'\n319 W Hastings street, #400 Vancouver, V6B1H6'}
                      </MediaQuery>
                      <MediaQuery maxWidth={mediaSize.maxPhoneSize - 1}>
                       { 'IN BC1267245\n'
                        + 'MSB M20081256\n'
                        + '\n' }
                        <strong className={styles.cardTextStrong}>{'@tometeam'}</strong>
                        {'\n'}
                        {'E-mail: office@tome.kz'}
                        { '\n319 W Hastings street,'
                          + '\n#400 Vancouver, V6B1H6' }
                      </MediaQuery>
                    </pre>
                  </div>
                </div>
                <div className={classNames(styles.card, styles.cardMap, styles.cardMapBottom)}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDO8F6GdMJ-ywMfoTRfrf-O9dmf1uMXj1o' }}
                    defaultCenter={coordinates.canada}
                    defaultZoom={16}
                    options={mapOptions}
                  >
                    <img
                      lat={coordinates.canada.lat}
                      lng={coordinates.canada.lng}
                      alt="pinMap"
                      /* eslint-disable-next-line max-len */
                      src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/information/pinMap.svg`}
                      className={styles.cardMapPin}
                    />
                  </GoogleMapReact>
                </div>
              </div>
            </div>
            <MediaQuery minWidth={mediaSize.maxPhoneSize}>
              <img
                alt="blobBottom"
                width={187}
                /* eslint-disable-next-line max-len */
                src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/contacts/information/blob_bottom.png`}
                className={styles.blobBottom}
              />
            </MediaQuery>
          </div>
        </div>
        <MediaQuery minWidth={mediaSize.maxPhoneSize}>
          <img
            alt="waveBottom"
            src={`https://cdn.tome.${window.location.hostname.split('.').at(-1)}/landing/innerPages/wave_bottom.webp`}
            className={styles.waveBottom}
          />
        </MediaQuery>
      </div>
    )
  }
}
